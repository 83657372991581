// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from '@modules/react';
import Button from '@modules/antd/lib/button';
import Checkbox, { CheckboxChangeEvent } from '@modules/antd/lib/checkbox';
import { Col, Row } from '@modules/antd/lib/grid';
import notification from '@modules/antd/lib/notification';
import Text from '@modules/antd/lib/typography/Text';

import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';
import {
    Project, Task, Job, CloudStorage, MLModel, CVATCore,
} from '@root/cvat-core-wrapper';
import indexedStorage, { ContextImages, DatabaseStore } from './indexed-storage';

type PreviewFor = Project | Task | Job | CloudStorage | MLModel;
type Quality = 'compressed' | 'original';

type PreviewEnterFactory = (store: DatabaseStore) => (
    (this: PreviewFor, _: CachePlugin) => Promise<{ preventMethodCallWithReturn: string } | undefined>
);
type PreviewLeaveFactory = (store: DatabaseStore) => (
    (this: PreviewFor, _: CachePlugin, result: string) => Promise<string>
);

interface CachePlugin {
    name: string;
    description: string;
    data: {
        core: CVATCore | null;
        enabled: boolean;
        cachedChunksByJob: Record<number, number[]>;
    };
    cvat: {
        classes: {
            Project: {
                prototype: {
                    preview: {
                        enter: ReturnType<PreviewEnterFactory>;
                        leave: ReturnType<PreviewLeaveFactory>;
                    };
                };
            };
            Task: {
                prototype: {
                    frames: {
                        preview: {
                            enter: ReturnType<PreviewEnterFactory>;
                            leave: ReturnType<PreviewLeaveFactory>;
                        };
                    };
                };
            };
            Job: {
                prototype: {
                    frames: {
                        preview: {
                            enter: ReturnType<PreviewEnterFactory>;
                            leave: ReturnType<PreviewLeaveFactory>;
                        };
                        contextImage: {
                            enter: (
                                this: Job,
                                plugin: CachePlugin,
                                frameNumber: number,
                            ) => Promise<{ preventMethodCallWithReturn: ContextImages } | undefined>;
                            leave: (
                                this: Job,
                                plugin: CachePlugin,
                                result: ContextImages,
                                frameNumber: number,
                            ) => Promise<ContextImages>;
                        };
                        chunk: {
                            enter: (
                                this: Job,
                                plugin: CachePlugin,
                                chunkIndex: number,
                                quality: Quality,
                            ) => Promise<{ preventMethodCallWithReturn: ArrayBuffer } | undefined>;
                            leave: (
                                this: Job,
                                plugin: CachePlugin,
                                result: ArrayBuffer,
                                chunkIndex: number,
                                quality: Quality,
                            ) => Promise<ArrayBuffer>
                        };
                        cachedChunks: {
                            leave: (this: Job, plugin: CachePlugin, result: number[]) => Promise<number[]>;
                        };
                    };
                };
            };
            CloudStorage: {
                prototype: {
                    preview: {
                        enter: ReturnType<PreviewEnterFactory>;
                        leave: ReturnType<PreviewLeaveFactory>;
                    };
                };
            };
            MLModel: {
                prototype: {
                    preview: {
                        enter: ReturnType<PreviewEnterFactory>;
                        leave: ReturnType<PreviewLeaveFactory>;
                    };
                };
            };
        };
    };
}

const previewEnter: PreviewEnterFactory = (
    store: DatabaseStore,
) => async function (this: PreviewFor, plugin: CachePlugin) {
    if (plugin.data.enabled) {
        const item = await indexedStorage.getItem<string>(store, `${this.id}`);
        if (item) {
            return { preventMethodCallWithReturn: item };
        }
    }

    return undefined;
};

const previewLeave: PreviewLeaveFactory = (store: DatabaseStore) => async function (
    this: PreviewFor, plugin: CachePlugin, result: string,
): Promise<string> {
    if (plugin.data.enabled && result) {
        await indexedStorage.setItem<string>(store, `${this.id}`, result);
    }
    return result;
};

async function contextImageEnter(
    this: Job, plugin: CachePlugin, frameNumber: number,
): Promise<undefined | { preventMethodCallWithReturn: ContextImages }> {
    if (plugin.data.enabled) {
        const key = `${this.id}_${frameNumber}`;
        const item = await indexedStorage
            .getItem<{ images: ContextImages; updatedDate: string }>(DatabaseStore.CONTEXT_IMAGES, key);

        if (item) {
            // make sure cached context images still valid. Remove them if not valid
            const { chunksUpdatedDate } = await plugin.data.core.frames.getMeta('job', this.id);
            if (chunksUpdatedDate !== item.updatedDate) {
                const keys = await indexedStorage.getKeysInStore(DatabaseStore.CONTEXT_IMAGES);
                const cachedContextImages = keys.filter((key: string) => +key.split('_')[0] === this.id);
                for await (const keyToDelete of cachedContextImages) {
                    await indexedStorage.deleteItem(DatabaseStore.CONTEXT_IMAGES, keyToDelete);
                }
                return undefined;
            }

            return { preventMethodCallWithReturn: item.images };
        }
    }

    return undefined;
}

async function contextImageLeave(
    this: Job, plugin: CachePlugin, result: ContextImages, frameNumber: number,
): Promise<ContextImages> {
    if (plugin.data.enabled && Object.keys(result).length) {
        const key = `${this.id}_${frameNumber}`;
        if (!await indexedStorage.hasItem(DatabaseStore.CONTEXT_IMAGES, key)) {
            const { chunksUpdatedDate } = await plugin.data.core.frames.getMeta('job', this.id);
            await indexedStorage
                .setItem<{ images: ContextImages; updatedDate: string }>(DatabaseStore.CONTEXT_IMAGES, key, {
                    images: result,
                    updatedDate: chunksUpdatedDate,
                });
        }
    }

    return result;
}

async function chunkEnter(
    this: Job, plugin: CachePlugin, chunkIndex: number, quality: Quality,
): Promise<undefined | { preventMethodCallWithReturn: ArrayBuffer }> {
    if (plugin.data.enabled && quality === 'compressed') {
        const key = `${this.id}_${chunkIndex}`;
        const item = await indexedStorage
            .getItem<{ buffer: ArrayBuffer, updatedDate: string }>(DatabaseStore.COMPRESSED_JOB_CHUNKS, key);

        if (item) {
            // make sure cached chunks still valid. Remove them if not valid
            const { chunksUpdatedDate } = await plugin.data.core.frames.getMeta('job', this.id);
            if (chunksUpdatedDate !== item.updatedDate) {
                const keys = await indexedStorage.getKeysInStore(DatabaseStore.COMPRESSED_JOB_CHUNKS);
                const cachedChunks = keys.filter((key: string) => +key.split('_')[0] === this.id);
                for await (const keyToDelete of cachedChunks) {
                    await indexedStorage.deleteItem(DatabaseStore.COMPRESSED_JOB_CHUNKS, keyToDelete);
                }
                plugin.data.cachedChunksByJob[this.id] = [];
                return undefined;
            }

            return { preventMethodCallWithReturn: item.buffer };
        }
    }

    return undefined;
}

async function chunkLeave(
    this: Job, plugin: CachePlugin, result: ArrayBuffer, chunkIndex: number, quality: Quality,
): Promise<ArrayBuffer> {
    if (plugin.data.enabled && quality === 'compressed' && result) {
        const { id } = this;
        const key = `${id}_${chunkIndex}`;
        if (!await indexedStorage.hasItem(DatabaseStore.COMPRESSED_JOB_CHUNKS, key)) {
            const { chunksUpdatedDate } = await plugin.data.core.frames.getMeta('job', this.id);
            const appended = await indexedStorage
                .setItem<{ buffer: ArrayBuffer, updatedDate: string }>(DatabaseStore.COMPRESSED_JOB_CHUNKS, key, {
                    buffer: result,
                    updatedDate: chunksUpdatedDate,
                });
            if (appended && id in plugin.data.cachedChunksByJob &&
                !plugin.data.cachedChunksByJob[id].includes(chunkIndex)
            ) {
                plugin.data.cachedChunksByJob[id].push(chunkIndex);
            }
        }
    }

    return result;
}

async function cachedChunksLeave(this: Job, plugin: CachePlugin, result: number[]): Promise<number[]> {
    if (!plugin.data.enabled) {
        return result;
    }

    const { id } = this;
    if (id in plugin.data.cachedChunksByJob) {
        const totalCachedChunks = Array.from(
            new Set(plugin.data.cachedChunksByJob[id].concat(result)),
        ).sort((a, b) => a - b);
        return totalCachedChunks;
    }

    // remove old data if user've changed the job
    plugin.data.cachedChunksByJob = {};
    try {
        // get chunk numbers from the cache, and keep them in memory
        const keys = await indexedStorage.getKeysInStore(DatabaseStore.COMPRESSED_JOB_CHUNKS);
        plugin.data.cachedChunksByJob[id] = keys.map((key: string) => {
            const [jobID, chunk] = key.split('_');
            return [+jobID, +chunk];
        }).filter(([jobID]) => jobID === id).map(([, chunk]) => chunk);

        const totalCachedChunks = Array.from(
            new Set(plugin.data.cachedChunksByJob[id].concat(result)),
        ).sort((a, b) => a - b);
        return totalCachedChunks;
    } catch (error) {
        console.error(error);
        return result;
    }
}

const plugin: CachePlugin = {
    name: 'Cache',
    description: 'Enables cache for some static data',
    data: {
        core: null,
        enabled: ['true', null].includes(window.localStorage.getItem('cvatEnableCacheFeature')),
        cachedChunksByJob: {},
    },
    cvat: {
        classes: {
            Project: {
                prototype: {
                    preview: {
                        enter: previewEnter(DatabaseStore.PROJECTS_PREVIEW),
                        leave: previewLeave(DatabaseStore.PROJECTS_PREVIEW),
                    },
                },
            },
            Task: {
                prototype: {
                    frames: {
                        preview: {
                            enter: previewEnter(DatabaseStore.TASKS_PREVIEW),
                            leave: previewLeave(DatabaseStore.TASKS_PREVIEW),
                        },
                    },
                },
            },
            Job: {
                prototype: {
                    frames: {
                        preview: {
                            enter: previewEnter(DatabaseStore.JOBS_PREVIEW),
                            leave: previewLeave(DatabaseStore.JOBS_PREVIEW),
                        },
                        contextImage: {
                            enter: contextImageEnter,
                            leave: contextImageLeave,
                        },
                        chunk: {
                            enter: chunkEnter,
                            leave: chunkLeave,
                        },
                        cachedChunks: {
                            leave: cachedChunksLeave,
                        },
                    },
                },
            },
            CloudStorage: {
                prototype: {
                    preview: {
                        enter: previewEnter(DatabaseStore.CLOUDSTORAGES_PREVIEW),
                        leave: previewLeave(DatabaseStore.CLOUDSTORAGES_PREVIEW),
                    },
                },
            },
            MLModel: {
                prototype: {
                    preview: {
                        enter: previewEnter(DatabaseStore.FUNCTIONS_PREVIEW),
                        leave: previewLeave(DatabaseStore.FUNCTIONS_PREVIEW),
                    },
                },
            },
        },
    },
};

const builder: ComponentBuilder = ({ core, actionCreators, store }) => {
    plugin.data.core = core;
    core.plugins.register(plugin);
    function SettingsComponent(): JSX.Element {
        const [enabled, setEnabled] = useState(plugin.data.enabled);
        const [isPersisted, setIsPersisted] = useState(true);
        const [quota, setQuota] = useState<Awaited<ReturnType<typeof indexedStorage.estimate>>>(null);

        useEffect(() => {
            navigator.storage.persisted().then((res) => setIsPersisted(res));
            indexedStorage.estimate().then((res) => setQuota(res));
        }, []);

        const availableGb = quota ? (quota.quota / (1024 * 1024 * 1024)).toFixed(2) : null;
        const usedGb = quota ? (quota.usage / (1024 * 1024 * 1024)).toFixed(2) : null;

        return (
            <Row>
                <Col span={24} className='cvat-player-settings-enable-cache cvat-player-setting'>
                    <Row>
                        <Checkbox
                            className='cvat-text-color'
                            checked={enabled}
                            onChange={(event: CheckboxChangeEvent): void => {
                                setEnabled(event.target.checked);
                                plugin.data.enabled = event.target.checked;
                                window.localStorage.setItem('cvatEnableCacheFeature', `${event.target.checked}`);
                            }}
                        >
                            Enable cache feature
                        </Checkbox>
                    </Row>
                    { !!quota && (
                        <Row>
                            <Col>
                                <Text type='secondary'>
                                    {`${availableGb} Gb available, ${usedGb} Gb in use`}
                                </Text>
                                <Button
                                    type='link'
                                    onClick={() => {
                                        indexedStorage.clear()
                                            .then(() => {
                                                plugin.data.cachedChunksByJob = {};
                                                return indexedStorage.estimate();
                                            }).then((res) => setQuota(res))
                                            .catch((err) => notification.error({
                                                message: 'Cache error occured',
                                                description: err.toString(),
                                            }));
                                    }}
                                >
                                    clear
                                </Button>
                                {
                                    !isPersisted && (
                                        <Button
                                            size='small'
                                            onClick={() => {
                                                navigator.storage.persist().then((persisted) => {
                                                    if (persisted) {
                                                        notification.info({ message: 'Persisted storage enabled' });
                                                        indexedStorage.estimate()
                                                            .then((updatedQuota) => setQuota(updatedQuota));
                                                        setIsPersisted(true);
                                                    } else {
                                                        notification.warning({ message: 'Requesting persisted storage declined' });
                                                    }
                                                });
                                            }}
                                        >
                                            Request persisted
                                        </Button>
                                    )
                                }
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        );
    }

    store.dispatch(actionCreators.addUIComponent('settings.player', SettingsComponent, { weight: -1 }));

    return {
        name: plugin.name,
        destructor: () => {
            store.dispatch(actionCreators.removeUIComponent('settings.player', SettingsComponent));
        },
    };
};

function register(): void {
    if (window.isSecureContext) {
        if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
            (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
                .cvatUI.registerComponent(builder);
        }
    }
}

window.addEventListener('plugins.ready', register, { once: true });
